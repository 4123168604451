const Session = {

    getToken() {
        const token = localStorage.getItem('token');
        if (token) return token;
        else return null;
    },

    getRole() {
        const usersRole = localStorage.getItem('usersRole');
        if (usersRole) return usersRole;
        else return null;
    },

    getUserId() {
        const userId = localStorage.getItem('userId');
        if (userId) return userId;
        else return null;
    },

    getUserName() {
        const userName = localStorage.getItem('userName');
        if (userName) return userName;
        else return null;
    },

    setUserSession(userName, token, userId, usersRole) {
        localStorage.setItem('userName', userName);
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('usersRole', usersRole);
    },

    removeUserSession() {
        localStorage.removeItem('userName');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('usersRole');
    }
}

export default Session;