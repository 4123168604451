import React, { Component } from 'react';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from '../util/util';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import '../styles/site.css';
import Moment from 'react-moment';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export class TABBScoreEntry extends Component {
    static displayName = TABBScoreEntry.name;

    constructor(props) {
        super(props);

        this.state = {
            clientOptions: [],
            client: null,
            clientText: null,
            search: null,
            candidates: [],
            isAdmin: false,
            isTabbAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            testType: 'Selection',
            testLevel: 'MA',
            sheetOptions: [{ value: "EA Selection", label: "EA Selection" }, { value: "EA Developmental", label: "EA Developmental" }, { value: "MA Selection", label: "MA Selection" }, { value: "MA Developmental", label: "MA Developmental" }, , { value: "HRE Selection", label: "HRE" }, { value: "Callouts Only", label: "Callouts Only" }],
            sheetOptionsData: null,
            uploadSuccess: "",
            fileChosen: false,
            modalOpen: false,
            dateSearch: "",
            dateSearchTo: "",
            scores: [],
            scoreValues: [],
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";

    }

    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.candidates.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.candidates.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.candidates.length / pageSize) })
        }
    }

    async componentDidMount() {
        this.setWindowDimensions();
        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            const isTabbAdmin = await AuthFunctions.isTabbAdminAsync();
            this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });
            if (isAdmin) {
                this.getClients();
            }
            else {
                this.getClientByUserId();
                this.showAllCandidates();
            }
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchCandidates();
        }
    }

    handleSelectAllChange = (event) => {
        var elements = document.getElementsByTagName("input");
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].type === "checkbox" && elements[i] !== event.target) {
                elements[i].checked = event.target.checked;
            }
        }
    }

    render() {
        // Logic for displaying candidates
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.candidates.slice(indexOfFirstItem, indexOfLastItem);
        const scoreData = this.state.scores;

        const renderItems = currentItems.map((candidate, i) => {
            return (
                <tr key={i}>
                    <td>{candidate.lastName}</td>
                    <td>{candidate.firstName}</td>
                    {this.state.isAdmin && (
                        <td>{candidate.client}</td>
                    )}
                    <td>{candidate.dateAssessmentScored !== null && <Moment format="MM/DD/YYYY">{candidate.dateAssessmentScored}</Moment>}</td>
                    <td>{candidate.levelType}</td>
                    <td>
                        <Button
                            color='primary'
                            onClick={() => { this.getScoreData(candidate.assessmentId); }}
                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', whiteSpace: 'nowrap' } : { flex: '1', whiteSpace: 'nowrap' }}
                            disabled={this.state.loading ? true : false}
                        >
                            Edit
                        </Button>
                    </td>
                    <td>
                        <Button
                            color='primary'
                            onClick={() => { this.getDefaultScoreData(candidate.assessmentId); }}
                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', whiteSpace: 'nowrap' } : { flex: '1', whiteSpace: 'nowrap' }}
                            disabled={this.state.loading ? true : false}
                        >
                            Edit From FM
                        </Button>
                    </td>

                </tr>
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.candidates.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        var groupName = "";

        const getScoreType= (scoreType) => {
            if (groupName !== scoreType) {
                groupName = scoreType;
                return groupName;
            } else {
                return "";
            }

        }
        const renderScores = scoreData.map((score, i) => {

            return (
                <Row key={i} style={{marginTop: '10px'}}>
                    <Col style={{fontWeight: 'bold'}}>{getScoreType(score.scoreType)}</Col>
                    <Col>{score.columnName}</Col>
                    <Col>
                        <input
                            type='text'
                            name={score.mappedName}
                            id={score.mappedName}
                            onChange={(e) => {
                                var values = this.state.scoreValues;
                                values[score.mappedName] = e.target.value;
                                this.setState({ scoreValues: values });
                            }}
                            value={this.state.scoreValues[score.mappedName]}
                        />
                    </Col>
                </Row>
            )
        });

        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <NavLink className="nav-button"
                                tag={Link}
                                style={{ padding: '0px' }}
                                to='/TABBMenu'>
                                TABB Tools
                            </NavLink>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <h3>TABB Score Entry</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row style={{ marginTop: '20px' }}>
                                <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                    <Input
                                        type='text'
                                        onChange={(e) => this.setState({ search: e.target.value })}
                                        value={this.state.search}
                                        name='search'
                                        id='search'
                                        onKeyPress={this.handleKeyPress}
                                        placeholder='Search Candidate Name, Position, or Status'

                                    />
                                </Col>
                                <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                    {this.state.isAdmin && (
                                        <Typeahead
                                            allowNew
                                            onChange={(selected) => this.setState({ client: selected })}
                                            onBlur={(e) => this.setState({ client: [e.target.value] })}
                                            selected={this.state.client}
                                            options={this.state.clientOptions}
                                            placeholder='Search Client'
                                            style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                            disabled={this.state.isAdmin === false ? true : false}
                                        />
                                    )}
                                    {!this.state.isAdmin && (
                                        <Typeahead
                                            allowNew
                                            onChange={(selected) => this.setState({ client: selected })}
                                            onBlur={(e) => this.setState({ client: [e.target.value] })}
                                            selected={this.state.clientOptions}
                                            options={this.state.clientOptions}
                                            placeholder='Search Client'
                                            style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                            disabled={this.state.isAdmin === false ? true : false}
                                        />
                                    )}
                                </Col>
                                <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            color='primary'
                                            onClick={() => { this.searchCandidates(); }}
                                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap' } : { flex: '1', whiteSpace: 'nowrap' }}
                                            disabled={this.state.loading ? true : false}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            color='primary'
                                            onClick={() => this.showAllCandidates()}
                                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                            disabled={this.state.loading ? true : false}
                                        >
                                            Show All
                                </Button>
                                    </div>
                                </Col>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12' style={{ textAlign: 'right'}}>
                                        From Date:
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12'>
                                        <Input
                                            type='date'
                                            onChange={(e) => this.setState({ dateSearch: e.target.value })}
                                            value={this.state.dateSearch}
                                            name='dateSearch'
                                            id='dateSearch'
                                            onKeyPress={this.handleKeyPress}
                                            placeholder='Date Search...'

                                        />
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12' style={{ textAlign: 'right' }}>
                                        To Date:
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12'>
                                        <Input
                                            type='date'
                                            onChange={(e) => this.setState({ dateSearchTo: e.target.value })}
                                            value={this.state.dateSearchTo}
                                            name='dateSearchTo'
                                            id='dateSearchTo'
                                            onKeyPress={this.handleKeyPress}
                                            placeholder='Date Search...'

                                        />
                                    </Col>
                                </Row>
                            </Row>
                            {this.state.candidates.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPage} onChange={this.handleChange}>
                                        {renderPageNumbers}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                            <Row style={{ marginTop: '20px' }}>
                                <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Last Name
                                            <FaSort
                                                        color={this.state.sort.column === 'lastName' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('lastName')}
                                                        className='sort-button'
                                                    />
                                                </th>
                                                <th>
                                                    First Name
                                            <FaSort
                                                        color={this.state.sort.column === 'firstName' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('firstName')}
                                                        className='sort-button'
                                                    />
                                                </th>
                                                {this.state.isAdmin && (
                                                    <th>
                                                        Client
                                                        <FaSort
                                                            color={this.state.sort.column === 'client' ? '#0a58ca' : 'black'}
                                                            onClick={() => this.handleSort('client')}
                                                            className='sort-button'
                                                        />
                                                    </th>
                                                )}
                                                <th>
                                                    Date Scored
                                            <FaSort
                                                        color={this.state.sort.column === 'dateAssessmentScored' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('dateAssessmentScored')}
                                                        className='sort-button'
                                                    />
                                                </th>

                                                <th>
                                                    Level + Type
                                            <FaSort
                                                        color={this.state.sort.column === 'levelType' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('levelType')}
                                                        className='sort-button'
                                                    />
                                                </th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loading === true && this.state.loadingDocument === false ? (
                                                <tr>
                                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                        <Spinner color="primary" children='' />
                                                    </td>
                                                </tr>

                                            ) : (
                                                <>
                                                    {!this.state.noResults ? (
                                                        <>
                                                            {renderItems}
                                                        </>
                                                    ) : (
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                            <h5>No Results Found</h5>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <div id="myModal" className="modal" style={this.state.modalOpen === false ? { display: 'none' } : { display: 'block' }}>
                                    <div className="modal-content" style={{ width: '800px' }}>
                                        <Row style={{backgroundColor: 'lightgrey'} }>
                                            <Col style={{fontWeight: 'bold', fontSize: '24px'}}>Type</Col>
                                            <Col style={{ fontWeight: 'bold', fontSize: '24px' }}>Score</Col>
                                            <Col style={{ fontWeight: 'bold', fontSize: '24px' }}>Value</Col>
                                        </Row>
                                        {renderScores}
                                        <div>
                                            <Button
                                                id="yesBtn"
                                                color='primary'
                                                onClick={() => { this.setState({ modalOpen: false }); this.saveScores(); }}
                                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading ? true : false}>
                                                Save Data
                                            </Button>
                                            <Button
                                                id="noBtn"
                                                color='secondary'
                                                onClick={() => { this.setState({ modalOpen: false }); }}
                                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading ? true : false}>
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            {this.state.candidates.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPage} onChange={this.handleChange}>
                                        {renderPageNumbers}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    // clients

    async getClients() {
        const token = Session.getToken();
        const response = await fetch('api/Clients/GetClientDropdownTextAll', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data, token: token });
    }

    async getClientByUserId() {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        // const client = [
        //     {
        //         id: data.id,
        //         company: data.company
        //     }
        // ];
        var clientOptionList = [data.company];
        this.setState({ client: data.company, clientOptions: clientOptionList });
    }

    // Date Search
    async searchDates() {
        this.setState({ loading: true });
        const token = Session.getToken();
        const date = this.state.dateSearch ? this.state.dateSearch : '';
        const dateTo = this.state.dateSearchTo ? this.state.dateSearchTo : '';
        const query = this.state.search ? this.state.search : '';
        const response = await fetch(`api/Candidates/searchDate?date=${date}&dateTo=${dateTo}&query=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ candidates: data, loading: false });
        this.setState({ token: token });
    }

    // assessment score data
    async getScoreData(assessmentId) {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();

        const response = await fetch(`api/Assessments/GetAssessmentScoreOverrides?assessmentId=${assessmentId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        const values = this.state.scoreValues;

        if (data.length > 0) {

            for (var i = 0; i < data.length; i++) {
                var value = data[i];
                values[value.mappedName] = value.scoreValue;
            }
        }

        this.setState({ scores: data, scoreValues: values, loading: false, modalOpen: true });
    }

    async getDefaultScoreData(assessmentId) {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();

        const response = await fetch(`api/Candidates/GetDefaultScoreData?assessmentId=${assessmentId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        const values = this.state.scoreValues;

        if (data.length > 0) {

            for (var i = 0; i < data.length; i++) {
                var value = data[i];
                values[value.mappedName] = value.scoreValue;
            }
        }

        this.setState({ scores: data, scoreValues: values, loading: false, modalOpen: true });
    }

    // candidates

    async searchCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const query = this.state.search ? this.state.search : '';
        const date = this.state.dateSearch ? this.state.dateSearch : '';
        const dateTo = this.state.dateSearchTo ? this.state.dateSearchTo : '';

        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }

        const response = await fetch(`api/Candidates/SearchCandidatesForExport?query=${query}&client=${client}&webViewable=false&useDate=false&dateSearch=${date}&dateSearchTo=${dateTo}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    async saveScores() {
        var scores = this.state.scores;
        var values = this.state.scoreValues;

        for (var i = 0; i < scores.length; i++) {
            var score = scores[i];
            score.scoreValue = values[score.mappedName];
        }

        const token = Session.getToken();

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(scores),
            headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        };

        try {
            const response = await fetch('api/assessments/UpdateAssessmentScoreOverrides', requestOptions);
            const data = await response.json();
            console.log(data);
        } catch (e) {
            console.log(e);
        }

        console.log(scores);
    }

    async showAllCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const response = await fetch(`api/Candidates/SearchCandidatesForExport?query=&client=&webViewable=false&useDate=false&dateSearch=`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.candidates;
        data.sort((a, b) => {
            var valA = a[column] === null ? '' : a[column];
            var valB = b[column] === null ? '' : b[column];
            return valA.localeCompare(valB);
        });
       //data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            candidates: data,
            sort: {
                column,
                direction
            }
        })
    }
}