import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from '../util/util';
import '../styles/site.css';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

export class CandidateSearch extends Component {
    static displayName = CandidateSearch.name;

    constructor(props) {
        super(props);

        this.state = {
            clientOptions: [],
            client: null,
            clientText: null,
            search: null,
            candidates: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            testType: 'Selection',
            testLevel: 'MA'
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }

    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.candidates.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.candidates.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.candidates.length / pageSize) })
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        this.setWindowDimensions();
        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            this.setState({ isAdmin: isAdmin });
            if (isAdmin) {
                this.getClients();
            }
            else {
                this.getClientByUserId();
                this.showAllCandidates();
            }
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchCandidates();
        }
    }

    render() {
        // Logic for displaying candidates
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.candidates.slice(indexOfFirstItem, indexOfLastItem);

        const renderItems = currentItems.map((candidate, i) => {
            return (
                <tr key={i}>
                    <td>{candidate.lastName}</td>
                    <td>{candidate.firstName}</td>
                    {this.state.isAdmin && (
                        <td>{candidate.client}</td>
                    )}
                    <td>{candidate.position}</td>
                    <td className='td-button' id={`assessment-${i}`}>
                        {(candidate.assessments === null || candidate.assessments.length < 1) && (
                            <div>
                                {candidate.portalStatusText}<br />
                                {candidate.reportDueDate ? new Date(candidate.reportDueDate).toLocaleDateString('en-US') : ""}
                                <UncontrolledTooltip
                                    modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                    innerClassName='hover-text'
                                    placement='top'
                                    target={`assessment-${i}`}
                                    >
                                    {candidate.hoverText}
                                </UncontrolledTooltip>
                            </div>
                        )}
                        {candidate.assessments !== null && candidate.assessments.length > 0 && (
                            <div>
                                {candidate.portalStatusText}<br />
                                <Button
                                    color='primary'
                                    onClick={() => this.getAssessmentDocuments(candidate.assessments)}
                                    size='sm'
                                    style={{ cursor: 'pointer' }}
                                    disabled={candidate.assessments === null ? true : false}
                                >
                                    {this.state.width < DIMENSIONS.MD ? ('...') : ('Download')}
                                </Button>
                                <UncontrolledTooltip
                                    modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                    innerClassName='hover-text'
                                    placement='top'
                                    target={`assessment-${i}`}
                                    
                                >
                                    {candidate.hoverText}
                                </UncontrolledTooltip>
                            </div>


                        )}
                    </td>
                    <td className='td-button' id={`coachingcard-${i}`}>
                        {(candidate.coachingCard !== null && candidate.coachingCard.documentId > 0) && (
                            <div>
                                <Button
                                    color='primary'
                                    onClick={() => this.getDocument(candidate.coachingCard.recordId)}
                                    size='sm'
                                    style={{ cursor: 'pointer' }}
                                    disabled={candidate.coachingCard === null ? true : false}
                                >
                                    {this.state.width < DIMENSIONS.MD ? ('...') : ('Download')}
                                </Button>
                                <UncontrolledTooltip
                                    modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                    innerClassName='hover-text'
                                    placement='top'
                                    target={`assessment-${i}`}
                                >
                                    {candidate.coachingCardStatus.hoverText}
                                </UncontrolledTooltip>
                            </div>

                        )}
                        {(candidate.coachingCard === null || !(candidate.coachingCard !== null && candidate.coachingCard.documentId > 0) ) && (
                            <>
                                <div>
                                    {candidate.coachingCardStatus.portalStatusText}
                                    <UncontrolledTooltip
                                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                        innerClassName='hover-text'
                                        placement='top'
                                        target={`coachingcard-${i}`}>
                                        {candidate.coachingCardStatus.hoverText}
                                    </UncontrolledTooltip>
                                </div>

                                
                            </>
                        )}
                    </td>
                    <td className='td-button' id={`feedbackreport-${i}`}>
                        {(candidate.feedbackReport !== null && candidate.feedbackReport.documentId > 0) && (
                            <div>
                                {candidate.feedbackReportStatus.portalStatusText}<br />
                                <Button
                                    color='primary'
                                    onClick={() => this.getDocument(candidate.feedbackReport.recordId)}
                                    size='sm'
                                    style={{ cursor: 'pointer' }}
                                    disabled={candidate.feedbackReport === null ? true : false}
                                >
                                    {this.state.width < DIMENSIONS.MD ? ('...') : ('Download')}
                                </Button>
                                <UncontrolledTooltip
                                    modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                    innerClassName='hover-text'
                                    placement='top'
                                    target={`feedbackreport-${i}`}>
                                    {candidate.feedbackReportStatus.hoverText}
                                </UncontrolledTooltip>
                            </div>

                        )}
                        {(candidate.feedbackReport === null || !(candidate.feedbackReport !== null && candidate.feedbackReport.documentId > 0)) && (
                            <>
                                <div>
                                    {candidate.feedbackReportStatus.portalStatusText}
                                </div>

                                <UncontrolledTooltip
                                    modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                    innerClassName='hover-text'
                                    placement='top'
                                    target={`feedbackreport-${i}`}>
                                    {candidate.feedbackReportStatus.hoverText}
                                </UncontrolledTooltip>
                            </>
                        )}

                    </td>
                </tr>
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.candidates.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Candidate Search</h3>
                        </Col>
                    </Row>
                    



                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                name='search'
                                id='search'
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search Candidate Name, Position, or Status'

                            />
                        </Col>
                        <Col xl='4' lg='4' md='6' sm='12' xs='12'>
                            {this.state.isAdmin && (
                                <Typeahead
                                    allowNew
                                    onChange={(selected) => this.setState({ client: selected })}
                                    onBlur={(e) => this.setState({ client: [e.target.value] })}
                                    selected={this.state.client}
                                    options={this.state.clientOptions}
                                    placeholder='Search Client'
                                    style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                    disabled={this.state.isAdmin === false ? true : false}
                                />
                            )}
                            {!this.state.isAdmin && (
                                <Typeahead
                                    allowNew
                                    onChange={(selected) => this.setState({ client: selected })}
                                    onBlur={(e) => this.setState({ client: [e.target.value] })}
                                    selected={this.state.clientOptions}
                                    options={this.state.clientOptions}
                                    placeholder='Search Client'
                                    style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                    disabled={this.state.isAdmin === false ? true : false}
                                />
                            )}
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2'>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    color='primary'
                                    onClick={() => this.searchCandidates()}
                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap' } : { flex: '1', whiteSpace: 'nowrap' }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Search
                                </Button>
                                <Button
                                    color='primary'
                                    onClick={() => this.showAllCandidates()}
                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Show All
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {this.state.candidates.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handleChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            {/* <ul id="page-numbers">
                            {this.state.currentPage > 1 ? (
                                <li key={0} id={0} onClick={this.handleClick}>First</li>
                            ) : ( 
                                null 
                            )}
                            {this.state.currentPage > 1 ? (
                                <li key={this.state.currentPage - 1} id={this.state.currentPage - 1} onClick={this.handleClick}>Prev</li>
                            ) : (
                                null
                            )} 
                            <li>{this.state.currentPage} of {pageNumbers.length}</li>
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={this.state.currentPage + 1} id={this.state.currentPage + 1} onClick={this.handleClick}>Next</li>
                            ) : (
                                null
                            )}
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={-1} id={-1} onClick={this.handleClick}>Last</li>
                            ) : (
                                null
                            )} 
                            </ul> */}
                        </div>
                    )}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            Last Name
                                            <FaSort
                                                color={this.state.sort.column === 'lastName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('lastName')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            First Name
                                            <FaSort
                                                color={this.state.sort.column === 'firstName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('firstName')}
                                                className='sort-button'
                                            />
                                        </th>
                                        {this.state.isAdmin && (
                                            <th>
                                                Client
                                                <FaSort
                                                    color={this.state.sort.column === 'client' ? '#0a58ca' : 'black'}
                                                    onClick={() => this.handleSort('client')}
                                                    className='sort-button'
                                                />
                                            </th>
                                        )}
                                        <th>
                                            Position
                                            <FaSort
                                                color={this.state.sort.column === 'position' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('position')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>Assessment
                                            <FaSort
                                                color={this.state.sort.column === 'status' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('status')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>Coaching Card</th>
                                        <th>Feedback Report</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true && this.state.loadingDocument === false ? (
                                        <tr>
                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                <Spinner color="primary" children='' />
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            {!this.state.noResults ? (
                                                <>
                                                    {renderItems}
                                                </>
                                            ) : (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                    <h5>No Results Found</h5>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {this.state.candidates.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handleChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            {/* <ul id="page-numbers">
                            {this.state.currentPage > 1 ? (
                                <li key={0} id={0} onClick={this.handleClick}>First</li>
                            ) : ( 
                                null 
                            )}
                            {this.state.currentPage > 1 ? (
                                <li key={this.state.currentPage - 1} id={this.state.currentPage - 1} onClick={this.handleClick}>Prev</li>
                            ) : (
                                null
                            )} 
                            <li>{this.state.currentPage} of {pageNumbers.length}</li>
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={this.state.currentPage + 1} id={this.state.currentPage + 1} onClick={this.handleClick}>Next</li>
                            ) : (
                                null
                            )}
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={-1} id={-1} onClick={this.handleClick}>Last</li>
                            ) : (
                                null
                            )} 
                            </ul> */}
                        </div>
                    )}
                </Container>
            </div>
        )
    }

    // clients

    async getClients() {
        const token = Session.getToken();
        const response = await fetch('api/Clients/GetClientDropdownText', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data });
    }

    async getClientByUserId() {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        // const client = [
        //     {
        //         id: data.id,
        //         company: data.company
        //     }
        // ];
        var clientOptionList = [data.company];
        this.setState({ client: data.company, clientOptions: clientOptionList });
    }

    // candidates

    async searchCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const query = this.state.search ? this.state.search : '';
        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }

        const response = await fetch(`api/Candidates/SearchCandidates?query=${query}&client=${client}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    async showAllCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const response = await fetch('api/Candidates/ShowAllCandidates', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }


    async getDocument(recordId) {
        const token = Session.getToken();
        const response = await fetch(`api/Candidates/GetDocument?recordId=${recordId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.downloadFile(data.documentBase64, data.documentTitle);
    }

    async getAssessmentDocuments(assessments) {
        const token = Session.getToken();
        const response = await fetch(`api/Candidates/GetAssessmentDocuments`, {
            method: "POST",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(assessments)
        })
        const data = await response.json();
        this.downloadFile(data.documentBase64, data.documentTitle);
    }

    async getAssessmentDocument(assessmentId, recordId, candidateId, testType = "", testLevel = "") {
        try {
            this.setState({ loading: true, loadingDocument: true });
            const token = Session.getToken();
            const response = await fetch(`api/Document/GetAssessmentDocument?assessmentId=${assessmentId}&clientId=${recordId}&candidateId=${candidateId}&testType=${testType}&testLevel=${testLevel}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            const data = await response.json();
            this.downloadFile(data.documentBase64, data.filename);
            this.setState({ loading: false, loadingDocument: false });
        } catch (ex) {
            this.setState({ loading: false, loadingDocument: false });
        }
        
    }

    downloadFile(file, name) {
        const blob = this.base64ToBlob(file);
        const uriContent = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', uriContent);
        link.setAttribute('download', name);
        const event = new MouseEvent('click');
        link.dispatchEvent(event);
    }

    base64ToBlob = (file) => {
        const binaryString = window.atob(file);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        return blob;
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.candidates;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            candidates: data,
            sort: {
                column,
                direction
            }
        })
    }
}