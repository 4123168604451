import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip, Alert } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from '../util/util';
import '../styles/site.css';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

export class ClientLogos extends Component {
    static displayName = ClientLogos.name;

    constructor(props) {
        super(props);

        this.state = {
            clientOptions: [],
            clientLogos: [],
            client: [],
            search: null,
            candidates: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            selectedFile: null,
            token: "",
            clientLogoURL: ""
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
    }

    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
    };

    onFileUpload = () => {
        //Do we have a client selected?
        if (this.state.client && this.state.client.length > 0 && this.state.client[0].clientRecordId > 0) {
            // Create an object of formData 
            this.state.uploadSuccess = "";
            const formData = new FormData();

            formData.append("formFile", this.state.selectedFile);
            formData.append("fileName", this.state.selectedFile.name);
            formData.append("RecordId", this.state.client[0].clientRecordId);
            formData.append("ClientCompany", this.state.client[0].clientCompany);

            // Update the formData object 
            //formData.append(
            //    "myFile",
            //    this.state.selectedFile,
            //    this.state.selectedFile.name
            //);

            // Details of the uploaded file 
            console.log(this.state.selectedFile);

            // Request made to the backend api 
            // Send formData object 

            const token = Session.getToken();

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            };
            fetch('api/Clients/UploadFile', requestOptions);
        } else {
            this.setState({ uploadSuccess: "clientRequired" });
        }


    };
    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.candidates.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.candidates.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.candidates.length / pageSize) })
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        this.setWindowDimensions();
        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            this.setState({ isAdmin: isAdmin });
            if (isAdmin) {
                this.getClientLogos();
            }
            else {
                this.getClientLogos();
            }
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter' && this.state.Admin == true) {
            this.getClientLogos();
        }
    }

    updateClient = (selected) => {
        this.setState({ client: selected });
        if (selected != null && selected.length > 0) {
            this.setState({ clientLogoURL: selected[0].clientLogoURL });
        }
    }
    render() {
        // Logic for displaying candidates
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.candidates.slice(indexOfFirstItem, indexOfLastItem);

        const renderItems = currentItems.map((candidate, i) => {
            return (
                <tr key={i}>
                    <td>{candidate.id}</td>
                    <td>{candidate.name}</td>
                    <td>{candidate.clientRecordId}</td>
                    <td>{candidate.clientCompany}</td>
                    <td>{candidate.clientLogoURL}</td>
                    <td>{candidate.fileName}</td>
                </tr>
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.candidates.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Client Logo Upload</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            {this.state.success === true && (
                                <Alert color='success'>
                                    Logo uploaded!
                                </Alert>
                            )}
                            {this.state.uploadSuccess === "clientRequired" && (
                                <Alert color='danger'>
                                    Client Required
                                </Alert>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <pre>Choose Client: </pre>
                        </Col>
                        <Col xl='4' lg='4' md='6' sm='12' xs='12'>
                            <Typeahead
                                id="Clients"
                                labelKey={option => `${option.clientCompany}`}
                                onChange={(selected) => this.updateClient(selected)}
                                selected={this.state.client}
                                options={this.state.candidates}
                                placeholder='Search Client'
                                style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                disabled={this.state.isAdmin === false ? true : false}
                            />
                        </Col>
                        <Col xl='4' lg='4' md='6' sm='12' xs='12'>
                            <div>
                                <input type="file" onChange={this.onFileChange} />
                                <button onClick={this.onFileUpload}>
                                    Upload
                                </button>
                            </div>
                        </Col>

                    </Row>
                    {/*<Row style={{ marginTop: '20px' }}>*/}
                    {/*    <pre>{JSON.stringify(this.state.client, null, 2)}</pre>*/}
                    {/*</Row>*/}
                    {this.state.clientLogoURL != null && this.state.clientLogoURL != "" ? (
                        <Row style={{ marginTop: '20px' }}>
                            Current Image: <img src={this.state.clientLogoURL} style={{ width: '261px', height: '82px' }}/>
                        </Row>
                    ) : (
                        <Row style={{ marginTop: '20px' }}>
                                No Current Image.
                        </Row>
                    )}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            Id
                                            <FaSort
                                                color={this.state.sort.column === 'Id' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('Id')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Name
                                            <FaSort
                                                color={this.state.sort.column === 'Name' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('Name')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            ClientRecordId
                                            <FaSort
                                                color={this.state.sort.column === 'ClientRecordId' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('ClientRecordId')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            ClientCompany
                                            <FaSort
                                                color={this.state.sort.column === 'ClientCompany' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('ClientCompany')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            ClientLogoURL
                                            <FaSort
                                                color={this.state.sort.column === 'ClientLogoURL' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('ClientLogoURL')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            FileName
                                            <FaSort
                                                color={this.state.sort.column === 'FileName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('FileName')}
                                                className='sort-button'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true ? (
                                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                            <Spinner color="primary" children='' />
                                        </Row>
                                    ) : (
                                        <>
                                            {!this.state.noResults ? (
                                                <>
                                                    {renderItems}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                            <h5>No Results Found</h5>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {this.state.candidates.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handleChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            {/* <ul id="page-numbers">
                            {this.state.currentPage > 1 ? (
                                <li key={0} id={0} onClick={this.handleClick}>First</li>
                            ) : ( 
                                null 
                            )}
                            {this.state.currentPage > 1 ? (
                                <li key={this.state.currentPage - 1} id={this.state.currentPage - 1} onClick={this.handleClick}>Prev</li>
                            ) : (
                                null
                            )} 
                            <li>{this.state.currentPage} of {pageNumbers.length}</li>
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={this.state.currentPage + 1} id={this.state.currentPage + 1} onClick={this.handleClick}>Next</li>
                            ) : (
                                null
                            )}
                            {this.state.currentPage < pageNumbers.length ? (
                                <li key={-1} id={-1} onClick={this.handleClick}>Last</li>
                            ) : (
                                null
                            )} 
                            </ul> */}
                        </div>
                    )}

                </Container>
            </div>
        )
    }

    // clients

    async getClients() {
        const token = Session.getToken();
        const response = await fetch('api/Clients/GetClientDropdownText', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data });
    }

    async getClientByUserId() {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        // const client = [
        //     {
        //         id: data.id,
        //         company: data.company
        //     }
        // ];
        this.setState({ client: data.company });
    }

    // client Logos

    async getClientLogos() {
        //const getLogos = await fetch('api/Clients/GetClientLogos');
        //const dataLogos = await getLogos.json();
        //this.setState({ clientLogos: dataLogos });
        this.setState({ loading: true });
        const token = Session.getToken();
        this.setState({ token: token });
        const query = this.state.search ? this.state.search : '';
        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }
        const response = await fetch(`api/Clients/GetClientLogos`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        //const response = await fetch(`api/Candidates/SearchCandidates?query=${query}&client=${client}`, {
        //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //});
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }
    async showAllCandidates() {
        this.setState({ loading: true });
        const token = Session.getToken();
        const response = await fetch('api/Candidates/ShowAllCandidates', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    // document

    async getDocument(recordId) {
        const token = Session.getToken();
        const response = await fetch(`api/Candidates/GetDocument?recordId=${recordId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.downloadFile(data.documentBase64, data.documentTitle);
    }

    downloadFile(file, name) {
        const blob = this.base64ToBlob(file);
        const uriContent = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', uriContent);
        link.setAttribute('download', name);
        const event = new MouseEvent('click');
        link.dispatchEvent(event);
    }

    base64ToBlob = (file) => {
        const binaryString = window.atob(file);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
        return blob;
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.candidates;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            candidates: data,
            sort: {
                column,
                direction
            }
        })
    }

    //async getClientByUserId() {
    //    const token = await authService.getAccessToken();
    //    const response = await fetch(`api/Clients/GetClientByUserId`, {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    })
    //    const data = await response.json();
    //    // const client = [
    //    //     {
    //    //         id: data.id,
    //    //         company: data.company
    //    //     }
    //    // ];
    //    //var clientOptionList = [data.company];
    //    this.setState({ client: data.company });
    //}
}
