import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { components } from "react-select";
import '../styles/site.css';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

export class TABBFileUpload extends Component {
    static displayName = TABBFileUpload.name;

    constructor(props) {
        super(props);

        this.state = {
            isAdmin: false,
            isTabbAdmin: false,
            height: 0,
            width: 0,
            loading: false,
            modalOpen: false,
            uploadMasterSuccess: "",
            masterFileChosen: false,
            masterSelectedFile: null,
            uploadComboSuccess: "",
            comboFileChosen: false,
            comboSelectedFile: null,
        };
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";

    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    onMasterFileChange = event => {
        // Update the state 
        this.setState({ masterSelectedFile: event.target.files[0], masterFileChosen: true });
    };

    onComboFileChange = event => {
        // Update the state 
        this.setState({ comboSelectedFile: event.target.files[0], comboFileChosen: true });
    };

    onMasterFileUpload = async () => {
        //Is the file that is uploaded a excell sheet?
        if (true) {
            this.setState({ loading: true });
            // Create an object of formData 
            this.state.uploadMasterSuccess = "";
            const formData = new FormData();

            formData.append("formFile", this.state.masterSelectedFile);
            formData.append("fileName", this.state.masterSelectedFile.name);

            // Details of the uploaded file 
            console.log(this.state.masterSelectedFile);

            // Request made to the backend api
            // Send formData object

            const token = Session.getToken();

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            };
            try {
                const response = await fetch('api/Clients/UploadFileTABB', requestOptions);
                const data = await response.json();
                if (data == "-1") {
                    this.setState({ uploadMasterSuccess: "An error occurred!" })
                } else {
                    this.setState({ uploadMasterSuccess: data + " records imported" })
                }
                console.log(data);
            } catch (e) {
                console.log(e);
            }
            this.setState({ loading: false });
            //fetch('api/Clients/UploadFile', requestOptions);
        } else {
            this.setState({ uploadMasterSuccess: "clientRequired" });
        }
    };

    onComboFileUpload = async () => {
        //Is the file that is uploaded a excell sheet?
        if (true) {
            this.setState({ loading: true });
            // Create an object of formData 
            this.state.uploadComboSuccess = "";
            const formData = new FormData();

            formData.append("formFile", this.state.comboSelectedFile);
            formData.append("fileName", this.state.comboSelectedFile.name);

            // Details of the uploaded file 
            console.log(this.state.comboSelectedFile);

            // Request made to the backend api 
            // Send formData object 
            const token = Session.getToken();

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            };
            try {
                const response = await fetch('api/Clients/UploadFileTABBCombo', requestOptions);
                const data = await response.json();
                if (data == "-1") {
                    this.setState({ uploadComboSuccess: "An error occurred!" })
                } else {
                    this.setState({ uploadComboSuccess: data + " records imported" })
                }
                console.log(data);
            } catch (e) {
                console.log(e);
            }
            this.setState({ loading: false });
            //fetch('api/Clients/UploadFile', requestOptions);
        } else {
            this.setState({ uploadComboSuccess: "clientRequired" });
        }
    };

    async componentDidMount() {
        this.setWindowDimensions();
        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            const isTabbAdmin = await AuthFunctions.isTabbAdminAsync();
            this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    render() {
        return (
            <div>
                {this.state.isTabbAdmin && (
                    <Container style={{ marginTop: '50px' }}>
                        <Row>
                            <Col>
                                <NavLink className="nav-button"
                                    tag={Link}
                                    style={{ padding: '0px' }}
                                    to='/TABBMenu'>
                                    TABB Tools
                                </NavLink>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                <h3>TABB Uploads</h3>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '50px' }}>
                            <Col>
                                <div>
                                    TABB Master File Upload:<br/>
                                    <input type="file" onChange={this.onMasterFileChange} />
                                    {this.state.fileChosen &&
                                        <button onClick={this.onMasterFileUpload} style={{ marginTop: '5px', marginBottom: '20px' }}>
                                            Upload TABB Master Excel
                                        </button>
                                    }
                                    {this.state.uploadMasterSuccess}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '50px', display: 'none' }}>
                            <Col>
                                <div>
                                    TABB Competency Combo File Upload:<br/>
                                    <input type="file" onChange={this.onComboFileChange} />
                                    {this.state.fileChosen &&
                                        <button onClick={this.onComboFileUpload} style={{ marginTop: '5px', marginBottom: '20px' }}>
                                            Upload TABB Competency Combo Excel
                                        </button>
                                    }
                                    {this.state.uploadComboSuccess}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )}
                {!this.state.isTabbAdmin && (
                    <Container style={{ marginTop: '50px' }}>
                        <Row>
                            <Col>
                                <h3>TABB Uploads</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            Not authorized!
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        )
    }
}