import React, { Component } from 'react';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';
import { Container, Row, Col, Button } from 'reactstrap';

export class TemplateManagement extends Component {
    static displayName = TemplateManagement.name;

    constructor(props) {
        super(props);

        this.state = {            
            isAdmin: false,
            height: 0,
            width: 0,            
        };

        window.addEventListener('resize', this.setWindowDimensions);
    }

    async componentDidMount() {
        this.setWindowDimensions();

        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            this.setState({ isAdmin: isAdmin });        
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    render() {
        return (
            <div>                                
                <Container style={{ marginTop: '50px' }}>                    
                    <Row>
                        <Col>
                            <h3>Template Management</h3>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col>                            
                            <Button
                                onClick={() => this.createAssessmentReport()}
                            >
                                Create Assessment Report
                            </Button>                            
                        </Col>                        
                        <Col>
                            <Button
                                onClick={() => this.importAssessmentResults()}
                            >
                                Import Assessment Results
                            </Button>
                        </Col>
                    </Row>
                </Container>                
            </div>
        )
    }
    
    async importAssessmentResults() {
        const token = Session.getToken();
        const response = await fetch(`api/Assessments/ImportAssessmentResults?recordId=${21018}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
    }

    async createAssessmentReport() {
        debugger
        const token = Session.getToken();
        const response = await fetch(`api/Assessments/CreateAssessmentReportAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.downloadFile(data.base64, data.filename);
    }

    downloadFile(file, name) {
        const blob = this.base64ToBlob(file);
        const uriContent = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', uriContent);
        link.setAttribute('download', name);
        const event = new MouseEvent('click');
        link.dispatchEvent(event);
    }

    base64ToBlob = (file) => {
		const binaryString = window.atob(file);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
          }
		const blob = new Blob([bytes], { type: 'application/pdf' });
		return blob;
	}

}