import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

export class FAQ extends Component {
    static displayName = FAQ.name;

    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
            isAdmin: false
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            this.setState({ isAdmin: isAdmin });
            document.getElementsByTagName('html')[0].className = "background-navigation";
        }
        else {
            document.getElementsByTagName('html')[0].className = "background-title";
        }
    }
    render() {
        //const styles = {
        //    question: {

        //    },
        //    answer: {

        //    },
        //    tableBorder: {
        //        border: "1px solid"
        //    }
        //};
        return (
            <div>
                {this.state.user ? (
                    <ul style={{ fontSize: '16px' }}>
                        <h3>FAQ</h3>
                        <p className="question"> Q:&nbsp;What does each status label mean? </p>
                        <p className="answer"> A:&nbsp;Please see the chart below for all statuses and their definitions: </p>
                        <table className="tableBorder tableCSS">
                            <thead>
                                <tr  className="tableBorder">
                                    <th  className="tableBorder tableHeader">
                                        Status
                                    </th>
                                    <th className="tableBorder tableHeader">
                                        Definition
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Order Initiated
                                    </td>
                                    <td  className="tableBorder">
                                        The candidate and/or client has been sent information on how to log in and complete assessments.
                                        If a proctored session and/or interview has been requested, status will remain as "Order Initiated" until scheduled.
                                    </td>
                                </tr>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Scheduled
                                        (proctored and Interview)
                                    </td>
                                    <td  className="tableBorder">
                                        The proctored session and/or interview, if applicable, has been scheduled.
                                    </td>
                                </tr>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Testing Available
                                    </td>
                                    <td  className="tableBorder">
                                        The candidate has been given their assessment links and may begin their assessment at any time.
                                    </td>
                                </tr>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Testing in Progress
                                    </td>
                                    <td  className="tableBorder">
                                        The candidate is in the process of completing their assessments.
                                        Status will remain as "Testing in Progress" until all assessments and interview, if applicable, have been completed and scored.
                                    </td>
                                </tr>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Testing Complete
                                    </td>
                                    <td  className="tableBorder">
                                        All parts of the assessment process have been completed. Report writing is in progress. Estimated due date is now available.
                                    </td>
                                </tr>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Delivered
                                    </td>
                                    <td  className="tableBorder">
                                        The full report is complete and available for download.
                                    </td>
                                </tr>
                                <tr  className="tableBorder">
                                    <td  className="tableBorder">
                                        Cancelled
                                    </td>
                                    <td  className="tableBorder">
                                        Either the client has cancelled, the candidate has dropped out of the process, or the candidate failed to complete all assessments prior to link expiration.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="question"> Q:&nbsp;Does this change the way we request our assessments? </p>
                        <p className="answer"> A:&nbsp;No, the assessment ordering and scheduling process remains the same. That said, please be sure to use the correct order form to avoid potential delays.</p>
                        <p className="question"> Q:&nbsp;I just placed an assessment order. Why is the candidate not listed? </p>
                        <p className="answer"> A:&nbsp;The candidate will appear in the system once the order has been initiated by PA. Please allow 1-2 business days.</p>
                        <p className="question"> Q:&nbsp;Is there a way to prevent an assessment from being posted? </p>
                        <p className="answer"> A:&nbsp;Orders that are submitted as "Confidential" will <span className="underline">not</span> be posted to the Q4 Compass. If necessary, you may also submit a request to have us remove an assessment that is currently posted.</p>
                        <p className="question"> Q:&nbsp;The candidate says they have completed their assessment. Why does your system still show "Testing in Progress"? </p>
                        <p className="answer"> A:&nbsp;The system will continue to show the candidate's status as "Testing in Progress" until all assessments have been scored by PA (and when applicable, the candidate interview has been completed).  </p>
                        <p className="question"> Q:&nbsp;Where can I find a report after I download it? </p>
                        <p className="answer"> A:&nbsp;You should see the report in the Compass window. If you do not, please check the download folder on your computer. </p>
                        <p className="question"> Q:&nbsp;What if my due date has passed and I do not see the report? </p>
                        <p className="answer"> A:&nbsp;Please contact your account manager or their assistant for more information.</p>
                        <p className="question"> Q:&nbsp;How long will my reports be available? </p>
                        <p className="answer"> A:&nbsp;Reports will be available via the Q4 Compass for a period of 2 years from the date the report was completed. If you need an older report, please contact your Account Manager or their assistant.  </p>
                        <p className="question"> Q:&nbsp;What if I forget my password? How do I change my password? </p>
                        <p className="answer"> A:&nbsp;Click on the "Forgot your password" link at the bottom of the log in page and enter the email address associated with your account. We will email a link which will allow you to reset your password. </p>
                        <p className="question"> Q:&nbsp;What if I have additional questions about the Q4 Compass that are not answered here? </p>
                        <p className="answer"> A:&nbsp;Please contact your account manager or their assistant for any additional help. </p> 
                    </ul>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
