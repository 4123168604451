import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { UserSearch } from './components/UserSearch';
import { UserManagement } from './components/UserManagement';
import { CandidateSearch } from './components/CandidateSearch';
import { AdminReportTABB } from './components/AdminReportTABB';
import { TABBMenu } from './components/TABBMenu';
import { TABBReports } from './components/TABBReports';
import { TABBFileUpload } from './components/TABBFileUpload';
import { TABBScoreEntry } from './components/TABBScoreEntry';
import { DataExport } from './components/DataExport';
import { Downloads } from './components/Downloads';
import { ClientLogos } from './components/ClientLogos';
import { FAQ } from './components/FAQ';
import { TOU } from './components/TOU';

import { Login } from './components/auth/Login';
import Session from './components/auth/Session';

//import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
//import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
//import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { ForgotPassword } from './components/ForgotPassword';
import { ResetPassword } from './components/ResetPassword';

import './custom.css'
import { Roles } from './components/admin/Roles';
import { CreateRole } from './components/admin/CreateRole';
import { RoleDetails } from './components/admin/RoleDetails';
import { TemplateManagement } from './components/TemplateManagement';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

const isProduction = process.env.NODE_ENV === 'production';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <CacheBuster
                currentVersion={packageInfo.version}
                isEnabled={isProduction} //If false, the library is disabled.
                isVerboseMode={true} //If true, the library writes verbose logs to console.
            >
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/Home' component={Home} />
                    <Route path='/UserSearch' component={UserSearch} />
                    <Route path='/UserManagement' component={UserManagement} />
                    <Route path='/CandidateSearch' component={CandidateSearch} />
                    <Route path='/TABBReports' component={TABBReports} />
                    <Route path='/TABBFileUpload' component={TABBFileUpload} />
                    <Route path='/TABBScoreEntry' component={TABBScoreEntry} />
                    <Route path='/TABBMenu' component={TABBMenu} />
                    <Route path='/DataExport' component={DataExport} />
                    <Route path='/Downloads' component={Downloads} />
                    <Route path='/AdminReportTABB' component={AdminReportTABB} />
                    <Route path='/ClientLogos' component={ClientLogos} />
                    <Route path='/FAQ' component={FAQ} />
                    <Route path='/TOU' component={TOU} />
                    <Route path='/TemplateManagement' component={TemplateManagement} />
                    {/*<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />*/}
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/logout' render={() => {
                        Session.removeUserSession();
                        window.location.href = '/login';
                        return <></>;
                    }} />
                    <Route exact path='/forgot' render={() => {
                        window.location.href = '/Identity/Account/ForgotPassword';
                        return <></>;
                    }} />
                    <Route exact path='/admin/roles' component={Roles} />
                    <Route exact path='/admin/roles/create' component={CreateRole} />
                    <Route exact path='/admin/roles/details' component={RoleDetails} />
                </Layout>
            </CacheBuster>
        );
    }
}

