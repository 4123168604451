import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from '../util/util';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import '../styles/site.css';
import Moment from 'react-moment';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export class DataExport extends Component {
    static displayName = DataExport.name;

    constructor(props) {
        super(props);

        this.state = {
            clientOptions: [],
            client: null,
            clientText: null,
            search: null,
            candidates: [],
            isAdmin: false,
            isTabbAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            testType: 'Selection',
            testLevel: 'MA',
            sheetOptions: [{ value: "EA Selection", label: "EA Selection" }, { value: "EA Developmental", label: "EA Developmental" }, { value: "MA Selection", label: "MA Selection" }, { value: "MA Developmental", label: "MA Developmental" }, , { value: "HRE Selection", label: "HRE" }, { value: "Callouts Only", label: "Callouts Only" }],
            sheetOptionsData: null,
            uploadSuccess: "",
            fileChosen: false,
            modalOpen: false,
            dateSearch: "",
            dateSearchTo: ""
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";

    }

    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0], fileChosen: true });
    };

    onFileUpload = async () => {
        //Is the file that is uploaded a excell sheet?
        if (true) {
            this.setState({ loading: true });
            // Create an object of formData 
            this.state.uploadSuccess = "";
            const formData = new FormData();

            formData.append("formFile", this.state.selectedFile);
            formData.append("fileName", this.state.selectedFile.name);
            //formData.append("RecordId", this.state.client[0].clientRecordId);
            //formData.append("ClientCompany", this.state.client[0].clientCompany);

            // Update the formData object 
            //formData.append(
            //    "myFile",
            //    this.state.selectedFile,
            //    this.state.selectedFile.name
            //);

            // Details of the uploaded file 
            console.log(this.state.selectedFile);

            // Request made to the backend api 
            // Send formData object 

            const token = Session.getToken();

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            };
            try {
                const response = await fetch('api/Clients/UploadFileTABB', requestOptions);
                const data = await response.json();
                if (data == "-1") {
                    this.setState({ uploadSuccess: "An error occurred!" })
                } else {
                    this.setState({ uploadSuccess: data + " records imported" })
                }
                console.log(data);
            } catch (e) {
                console.log(e);
            }
            this.setState({ loading: false });
            //fetch('api/Clients/UploadFile', requestOptions);
        } else {
            this.setState({ uploadSuccess: "clientRequired" });
        }


    };

    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.candidates.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.candidates.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.candidates.length / pageSize) })
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        this.setWindowDimensions();
        await this.checkAuthenticated();
        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            const isTabbAdmin = await AuthFunctions.isTabbAdminAsync();
            this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });
            if (isAdmin) {
                this.getClients();
            }
            else {
                this.getClientByUserId();
                this.showAllCandidates();
            }
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchCandidates();
        }
    }

    handleSelectAllChange = (event) => {
        var elements = document.getElementsByTagName("input");
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].type === "checkbox" && elements[i] !== event.target) {
                elements[i].checked = event.target.checked;
            }
        }
    }

    render() {
        // Logic for displaying candidates
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.candidates.slice(indexOfFirstItem, indexOfLastItem);

        const renderItems = currentItems.map((candidate, i) => {
            return (
                <tr key={i}>
                    <td>{candidate.lastName}</td>
                    <td>{candidate.firstName}</td>
                    {this.state.isAdmin && (
                        <td>{candidate.client}</td>
                    )}
                    <td>{candidate.dateAssessmentScored !== null && <Moment format="MM/DD/YYYY">{candidate.dateAssessmentScored}</Moment>}</td>
                    <td>{candidate.levelType}</td>
                </tr>
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.candidates.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Data Export</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2}>
                            <div id="testGroups">
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Select All</label>	<input type='checkbox' id='CheckAll' name='CheckAll' value='All' onChange={this.handleSelectAllChange} /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>DMM</label>	<input type='checkbox' id='DMM' name='DMM' value='DMM' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Watson II</label>	<input type='checkbox' id='Watson' name='Watson' value='Watson' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Thurstone</label>	<input type='checkbox' id='Thurstone' name='Thurstone' value='Thurstone' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Raven v2.0</label>	<input type='checkbox' id='Ravenv20' name='Raven v2.0' value='Raven 2.0' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Raven SPM+</label>	<input type='checkbox' id='RavenSPM' name='Raven SPM+' value='Raven SPMP' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Verify Num. (Op.)</label>	<input type='checkbox' id='VerifyNumericalOperational' name='Verify Numerical (Operational)' value='Verify Numerical (Operational)' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Verify Num. (Sup.)</label>	<input type='checkbox' id='VerifyNumiericalSupervisory' name='Verify Numerical (Supervisory)' value='Verify Numerical (Supervisory)' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Verify Num. (Prof.)</label>	<input type='checkbox' id='VerifyNumericalProfessional' name='Verify Numerical (Professional)' value='Verify Numerical (Professional)' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>Verify Verbal</label>	<input type='checkbox' id='VerifyVerbalReasoning' name='Verify Verbal Reasoning' value='Verify Verbal Reasoning' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>LOQ</label>	<input type='checkbox' id='LeadershipOpinioQuestionnaire' name='Leadership Opinion Questionnaire' value='LOQ' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>PRF</label>	<input type='checkbox' id='PersonalityResearch FormPRF' name='Personality Research Form (PRF)' value='PRF' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>16PF</label>	<input type='checkbox' id='16PersonalityFactor16PF' name='16 Personality Factor (16PF)' value='16PF' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>OPQ</label>	<input type='checkbox' id='OccupationalPersonalityQuestionnaireOPQ' name='Occupational Personality Questionnaire (OPQ)' value='OPQ' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                    <label>CPI</label>	<input type='checkbox' id='CaliforniaPersonalityInventoryCPI' name='California Personality Inventory (CPI)' value='CPI' /><br />
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                </div>
                                <div className="col" style={{ textAlign: 'right' }}>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Row style={{ marginTop: '20px' }}>
                                <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                    <Input
                                        type='text'
                                        onChange={(e) => this.setState({ search: e.target.value })}
                                        value={this.state.search}
                                        name='search'
                                        id='search'
                                        onKeyPress={this.handleKeyPress}
                                        placeholder='Search Candidate Name, Position, or Status'

                                    />
                                </Col>
                                <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                    {this.state.isAdmin && (
                                        <Typeahead
                                            allowNew
                                            onChange={(selected) => this.setState({ client: selected })}
                                            onBlur={(e) => this.setState({ client: [e.target.value] })}
                                            selected={this.state.client}
                                            options={this.state.clientOptions}
                                            placeholder='Search Client'
                                            style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                            disabled={this.state.isAdmin === false ? true : false}
                                        />
                                    )}
                                    {!this.state.isAdmin && (
                                        <Typeahead
                                            allowNew
                                            onChange={(selected) => this.setState({ client: selected })}
                                            onBlur={(e) => this.setState({ client: [e.target.value] })}
                                            selected={this.state.clientOptions}
                                            options={this.state.clientOptions}
                                            placeholder='Search Client'
                                            style={this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                            disabled={this.state.isAdmin === false ? true : false}
                                        />
                                    )}
                                </Col>
                                <Col xl='4' lg='4' md='4' sm='12' xs='12'>
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            color='primary'
                                            onClick={() => { this.searchCandidates(); }}
                                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap' } : { flex: '1', whiteSpace: 'nowrap' }}
                                            disabled={this.state.loading ? true : false}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            color='primary'
                                            onClick={() => this.showAllCandidates()}
                                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                            disabled={this.state.loading ? true : false}
                                        >
                                            Show All
                                </Button>
                                        <Button
                                            id="myBtn"
                                            color='primary'
                                            onClick={() => { this.exportDataCount(); }}
                                            style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                            disabled={this.state.loading ? true : false}
                                        >
                                            Export Data
                                </Button>
                                    </div>
                                </Col>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12' style={{ textAlign: 'right'}}>
                                        From Date:
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12'>
                                        <Input
                                            type='date'
                                            onChange={(e) => this.setState({ dateSearch: e.target.value })}
                                            value={this.state.dateSearch}
                                            name='dateSearch'
                                            id='dateSearch'
                                            onKeyPress={this.handleKeyPress}
                                            placeholder='Date Search...'

                                        />
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12' style={{ textAlign: 'right' }}>
                                        To Date:
                                    </Col>
                                    <Col xl='2' lg='2' md='2' sm='12' xs='12'>
                                        <Input
                                            type='date'
                                            onChange={(e) => this.setState({ dateSearchTo: e.target.value })}
                                            value={this.state.dateSearchTo}
                                            name='dateSearchTo'
                                            id='dateSearchTo'
                                            onKeyPress={this.handleKeyPress}
                                            placeholder='Date Search...'

                                        />
                                    </Col>
                                </Row>
                            </Row>
                            <Row>
                                <div id="myModal" className="modal" style={this.state.modalOpen === false ? { display: 'none' } : { display: 'block'}}>
                                    <div className="modal-content" style={{width: '800px'}}>
                                        <span className="close" onClick={() => this.setState({ modalOpen: false })}>&times;</span>
                                        {this.state.showEmail && (
                                            <p>An E-mail will be sent when the exported raw data is ready to view.</p>
                                        )}
                                        {this.state.showCount && (
                                            <div>
                                                <span>There are {this.state.exportCount} assessments that will be exported.  Continue?</span>
                                                <Button
                                                    id="yesBtn"
                                                    color='primary'
                                                    onClick={() => { this.setState({ modalOpen: false, showEmail: false, showCount: false }); this.exportData(); }}
                                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                                    disabled={this.state.loading ? true : false}>
                                                    Export Data
                                                </Button>
                                                <Button
                                                    id="noBtn"
                                                    color='secondary'
                                                    onClick={() => { this.setState({ modalOpen: false, showEmail: false, showCount: false}); }}
                                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', marginLeft: '20px', whiteSpace: 'nowrap' } : { flex: '1', marginLeft: '20px', whiteSpace: 'nowrap' }}
                                                    disabled={this.state.loading ? true : false}>
                                                    Cancel
                                                </Button>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </Row>
                            {this.state.candidates.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPage} onChange={this.handleChange}>
                                        {renderPageNumbers}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                            <Row style={{ marginTop: '20px' }}>
                                <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Last Name
                                            <FaSort
                                                        color={this.state.sort.column === 'lastName' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('lastName')}
                                                        className='sort-button'
                                                    />
                                                </th>
                                                <th>
                                                    First Name
                                            <FaSort
                                                        color={this.state.sort.column === 'firstName' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('firstName')}
                                                        className='sort-button'
                                                    />
                                                </th>
                                                {this.state.isAdmin && (
                                                    <th>
                                                        Client
                                                        <FaSort
                                                            color={this.state.sort.column === 'client' ? '#0a58ca' : 'black'}
                                                            onClick={() => this.handleSort('client')}
                                                            className='sort-button'
                                                        />
                                                    </th>
                                                )}
                                                <th>
                                                    Date Scored
                                            <FaSort
                                                        color={this.state.sort.column === 'dateAssessmentScored' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('dateAssessmentScored')}
                                                        className='sort-button'
                                                    />
                                                </th>

                                                <th>
                                                    Level + Type
                                            <FaSort
                                                        color={this.state.sort.column === 'levelType' ? '#0a58ca' : 'black'}
                                                        onClick={() => this.handleSort('levelType')}
                                                        className='sort-button'
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loading === true && this.state.loadingDocument === false ? (
                                                <tr>
                                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                        <Spinner color="primary" children='' />
                                                    </td>
                                                </tr>

                                            ) : (
                                                <>
                                                    {!this.state.noResults ? (
                                                        <>
                                                            {renderItems}
                                                        </>
                                                    ) : (
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                            <h5>No Results Found</h5>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            {this.state.candidates.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPage} onChange={this.handleChange}>
                                        {renderPageNumbers}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    // clients

    async getClients() {
        const token = Session.getToken();
        const response = await fetch('api/Clients/GetClientDropdownTextAll', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data, token: token });
    }

    async getClientByUserId() {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        // const client = [
        //     {
        //         id: data.id,
        //         company: data.company
        //     }
        // ];
        var clientOptionList = [data.company];
        this.setState({ client: data.company, clientOptions: clientOptionList });
    }

    // Date Search
    async searchDates() {
        this.setState({ loading: true });
        const token = Session.getToken();
        const date = this.state.dateSearch ? this.state.dateSearch : '';
        const dateTo = this.state.dateSearchTo ? this.state.dateSearchTo : '';
        const query = this.state.search ? this.state.search : '';
        const response = await fetch(`api/Candidates/searchDate?date=${date}&dateTo=${dateTo}&query=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ candidates: data, loading: false });
        this.setState({ token: token });
    }

    // candidates

    async searchCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const query = this.state.search ? this.state.search : '';
        const date = this.state.dateSearch ? this.state.dateSearch : '';
        const dateTo = this.state.dateSearchTo ? this.state.dateSearchTo : '';

        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }

        const response = await fetch(`api/Candidates/SearchCandidatesForExport?query=${query}&client=${client}&webViewable=false&useDate=false&dateSearch=${date}&dateSearchTo=${dateTo}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    async showAllCandidates() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const response = await fetch(`api/Candidates/SearchCandidatesForExport?query=&client=&webViewable=false&useDate=false&dateSearch=`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        this.setState({ candidates: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    async exportDataCount() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const query = this.state.search ? this.state.search : '';
        const date = this.state.dateSearch ? this.state.dateSearch : '';
        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }
        let group = [];

        var elements = document.getElementsByTagName("input");
        var groupParam = "";
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].checked) {
                groupParam = groupParam + "&group=" + elements[i].value;
                group.push(elements[i].value);
            }
        }

        //group = JSON.stringify(group);

        if (groupParam !== "") {
            const response = await fetch(`api/Candidates/ExportCandidatesToCSV?query=${query}&countOnly=true&client=${client}${groupParam}&dateSearch=${date}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();

            this.setState({ exportCount: data.foundCount, showCount: true, modalOpen: true })
            console.log(data);
        }

        this.setState({ loading: false });
    }

    async exportData() {
        this.setState({ loading: true, loadingDocument: false });
        const token = Session.getToken();
        const query = this.state.search ? this.state.search : '';
        const date = this.state.dateSearch ? this.state.dateSearch : '';
        //const clientId = (this.state.client && this.state.client.length !== 0) ? this.state.client[0].id : 0;
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }
        let group = [];

        var elements = document.getElementsByTagName("input");
        var groupParam = "";
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].checked) {
                groupParam = groupParam + "&group=" + elements[i].value;
                group.push(elements[i].value);
            }
        }

        //group = JSON.stringify(group);

        if (groupParam != "") {
            const response = fetch(`api/Candidates/ExportCandidatesToCSV?query=${query}&client=${client}${groupParam}&dateSearch=${date}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
        }

        //const data = await response.json();
        this.setState({ modalOpen: true, showEmail: true, loading: false });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.candidates;
        data.sort((a, b) => {
            var valA = a[column] === null ? '' : a[column];
            var valB = b[column] === null ? '' : b[column];
            return valA.localeCompare(valB);
        });
       //data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            candidates: data,
            sort: {
                column,
                direction
            }
        })
    }
}