import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

export class TABBMenu extends Component {
    static displayName = TABBMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
            isAdmin: false,
            isTabbAdmin: false,
        }
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        await this.checkAuthenticated();

        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            const isTabbAdmin = await AuthFunctions.isTabbAdminAsync();
            this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });
            document.getElementsByTagName('html')[0].className = "background-ring";
        }
        else {
            document.getElementsByTagName('html')[0].className = "background-title";
        }
    }

    render() {
        return (
            <div>
                {this.state.user ? (
                    <ul style={{ fontSize: '24px', paddingTop: '10%' }}>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/TABBReports'
                            hidden={!this.state.isAdmin ? true : false}
                        >
                            TABB Reports
                        </NavLink>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/TABBFileUpload'
                            hidden={!this.state.isTabbAdmin ? true : false}
                        >
                            TABB File Uploads
                        </NavLink>
                        <NavLink className="nav-button"
                            tag={Link}
                            to='/TABBScoreEntry'
                            hidden={!this.state.isTabbAdmin ? true : false}
                        >
                            TABB Score Entry
                        </NavLink>
                    </ul>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
