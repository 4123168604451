import React, { Component } from 'react';
import AuthFunctions from './services/AuthService';
import { Container, Row, Col, Input, Button, Table, Spinner } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from '../util/util';
import '../styles/site.css';
import Session from './auth/Session';

export class UserSearch extends Component {
    static displayName = UserSearch.name;

    constructor(props) {
        super(props);              

        this.state = {
            clientOptions: [],
            client: null,
            search: null,
            users: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false
        };

        document.getElementsByTagName('html')[0].className = "background-ring";
        window.addEventListener('resize', this.setWindowDimensions);
    }    

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        this.setWindowDimensions();
        await this.checkAuthenticated();

        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            const isTabbAdmin = await AuthFunctions.isTabbAdminAsync();
            this.setState({ isAdmin: isAdmin, isTabbAdmin: isTabbAdmin });

            if (isAdmin) {
                this.getClients();
            }
            else {
                this.getClientByUserId();
            }
            this.getUsers();
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    render() {                        
        return (
            <div>
                <Container style={{ marginTop: '50px'}} fluid>
                    <Row>
                        <Col>
                            <h3>User Search</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                name='search'
                                id='search'
                                placeholder='Search User Name'
                            />
                        </Col>
                        <Col xl='4' lg='4' md='6' sm='12' xs='12'>                            
                            <Typeahead
                                id='client'
                                labelKey='company'
                                onChange={(selected) => this.setState({ client: selected })}
                                selected={this.state.client}
                                options={this.state.clientOptions}
                                placeholder='Search Client'
                                style={ this.state.width < DIMENSIONS.MD ? { marginTop: '20px' } : {}}
                                disabled={this.state.isAdmin === false ? true : false}
                            />
                        </Col>                        
                        <Col xl='1' lg='1' md='2' sm='2' xs='2'>
                            <Button
                                color='primary'
                                onClick={ () => this.searchUsers() }
                                style={ this.state.width < DIMENSIONS.LG ? { marginTop: '20px' } : { }}
                                disabled={this.state.loading === true ? true : false}
                            >
                                Search
                            </Button>                            
                        </Col>                        
                        <Col xl='1' lg='1' md='2' sm='2' xs='2'>
                            <Button
                                color='primary'                                
                                onClick={() => this.props.history.push({ pathname: '/UserManagement', state: { hasUser: false }})}
                                style={ this.state.width < DIMENSIONS.LG ? { marginTop: '20px', whiteSpace: 'nowrap' } : { whiteSpace: 'nowrap' }}
                                disabled={this.state.isAdmin === false || this.state.loading === true ? true : false}
                            >
                                New User
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px'}}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>
                                            Last Name
                                            <FaSort
                                                color={this.state.sort.column === 'lastName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('lastName')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            First Name
                                            <FaSort
                                                color={this.state.sort.column === 'firstName' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('firstName')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Client
                                            <FaSort
                                                color={this.state.sort.column === 'client' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('client')}
                                                className='sort-button'
                                            />
                                        </th>                                        
                                        <th>
                                            Role
                                            <FaSort
                                                color={this.state.sort.column === 'role' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('role')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Email
                                            <FaSort
                                                color={this.state.sort.column === 'email' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('email')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true ? (
                                        <tr>
                                            <td colSpan="6">
                                                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                    <Spinner color="primary" children='' />
                                                </Row>
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {!this.state.noResults ? (
                                                <>
                                                    {this.state.users.map((user, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{user.lastName}</td>
                                                                <td>{user.firstName}</td>
                                                                <td>{user.client}</td>                                                
                                                                <td>{user.role}</td>
                                                                <td>{user.email}</td>
                                                                <td>
                                                                    <Button
                                                                        color='primary'
                                                                        onClick={() => this.handleRowClick(user.id)}
                                                                        style={{ cursor: 'pointer' }}
                                                                        disabled={this.state.isAdmin === false || this.state.loading === true ? true : false}
                                                                    >
                                                                        Edit
                                                                    </Button>

                                                                </td>                    
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan="6">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                            <h5>No Results Found</h5>
                                                        </div>  
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}                                    
                                </tbody>
                            </Table>
                        </Col>
                    </Row>                                                              
                </Container>
            </div>
        )
    }

    // clients

    async getClients() {        
        const token = Session.getToken();
        const response = await fetch('api/Clients/GetClientDropdown', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data });
    }

    async getClientByUserId() {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
        const data = await response.json();
        const client = [
            {
                id: data.id,
                company: data.company
            }
        ];
        this.setState({ client: client });
    }

    // users

    async getUsers() {
        this.setState({ loading: true });
        const token = Session.getToken();
        const response = await fetch('api/Users/GetUsers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ users: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    async searchUsers() {        
        if(!this.state.search && !this.state.client) {
            this.getUsers();
        }
        else {
            this.setState({ loading: true });
            const token = Session.getToken();
            const query = this.state.search ? this.state.search : '';
            const clientId = this.state.client ? this.state.client[0].id : 0;
            const response = await fetch(`api/Users/SearchUsers?query=${query}&clientId=${clientId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
            const data = await response.json();
            this.setState({ users: data, loading: false, noResults: data.length === 0 ? true : false });
        }        
    }

    // table on click

    handleRowClick(id) {        
        this.props.history.push({
            pathname: '/UserManagement',
            state: { userId: id, existingUser: true }
        });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.users;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if(direction === 'desc') {
            data.reverse();
        }
        this.setState({
            users : data,
            sort: {
                column,
                direction
            }
        })
    }

}