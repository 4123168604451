import React, { Component } from 'react';
import AuthFunctions from '../services/AuthService';
import Session from './Session';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import { FaEye } from 'react-icons/fa';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            passwordType: 'password',
            loggingIn: false,
            username: '',
            password: '',
            usernameRequired: false,
            passwordRequired: false,
            signinFailed: false,
        };
        document.getElementsByTagName('html')[0].className = "background-login";
    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (isAuthenticated) {
            window.location.href = '/';
            //this.props.history.push('/');
        }
    }

    async componentDidMount() {
        await this.checkAuthenticated();

    }

    validateInfo = () => {
        var username = this.state.username;
        var password = this.state.password;
        var usernameMissing = false;
        var passwordMissing = false;

        if (username.trim().length === 0) {
            usernameMissing = true;
        }

        if (password.trim().length === 0) {
            passwordMissing = true;
        }

        this.setState({ usernameMissing: usernameMissing, passwordMissing: passwordMissing, loggingIn: false, signinFailed: false });

        return !usernameMissing && !passwordMissing;
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (this.validateInfo()) {
                this.submitLogin();
            }
        }
    }

    async submitLogin() {
        this.setState({ loggingIn: true });
        if (this.validateInfo()) {
            var tokenData = await AuthFunctions.signInAsync(this.state.username, this.state.password);

            this.setState({ loggingIn: false });

            if (tokenData.token === null || tokenData.token.length === 0) {
                this.setState({ signinFailed: true });
            }
            else {
                this.setState({ signinFailed: false });
                Session.setUserSession(tokenData.userName, tokenData.token, tokenData.userId, tokenData.userRoles);
                window.location.href = '/';
            }
        }
    }

    render() {

        const logo = {
            width: '100%',
            display: 'block',
            margin: '0 auto'
        }

        return (
            <div className='main' style={{marginTop: '20px'}}>
                <Container>
                    <Row className="row text-center">
                        <div className="login-content">
                            <section style={{ margin: '20px' }}>
                                {this.state.signinFailed &&
                                    <span style={{ color: 'red' }}>Username or password is incorrect!</span>
                                }
                                <div className="form-group">
                                    <Input
                                        type='text'
                                        onChange={(e) => this.setState({ username: e.target.value, usernameMissing: false })}
                                        className='form-control'
                                        value={this.state.username}
                                        onKeyPress={(e) => this.handleKeyPress(e)}
                                        placeholder='Username'
                                    />
                                    {this.state.usernameMissing &&
                                        <span style={{ color: 'red' }}>Username is required!</span>
                                    }
                                    <span asp-validation-for="Input.Email" className="text-danger"></span>
                                </div>
                                <div className="form-group">
                                    <Input
                                        type={this.state.passwordType}
                                        onChange={(e) => this.setState({ password: e.target.value, passwordMissing: false })}
                                        className='form-control'
                                        value={this.state.password}
                                        onKeyPress={(e) => this.handleKeyPress(e)}
                                        placeholder='Password'
                                    />
                                    <FaEye className="password-eye"
                                        style={{ marginRight: '15px', cursor: 'pointer', float: 'right', marginTop: '-25px' }}
                                        onClick={() => {
                                            var type = this.state.passwordType === 'password' ? 'text' : 'password';
                                            this.setState({ passwordType: type });
                                        }}
                                    />
                                    {this.state.passwordMissing &&
                                        <span style={{color: 'red'}}>Password is required!</span>
                                    }
                                    <span asp-validation-for="Input.Password" className="text-danger"></span>
                                </div>
                                <Row>
                                    <Col>
                                        <p className="pull-left">
                                            <NavLink tag={Link} className="forgot-link text-dark" to={'/forgot'}>Forgot password?</NavLink>
                                        </p>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button className='standardButton btn btn-primary' disabled={this.state.loggingIn} style={{ width: '100px' }} onClick={() => {
                                            this.submitLogin();
                                        }}>
                                            Log In
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                </Row>
                            </section>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

