import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';

export class ResetPassword extends Component {
    static displayName = ResetPassword.name;

    render() {
        const logo = {
            width: '250px',
            padding: '1.5em',
            margin: '20px auto',
            display: 'block'
        }

        return (
            <Container>

                <Row style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Col xl='4' lg='4' md='12' sm='12' xs='12'></Col>
                    <Col xl='4' lg='4' md='12' sm='12' xs='12'>
                        <div>
                            <img src="logo.jpg" alt="logo" style={logo} />
                        </div>
                        <p>
                            Please enter a new password below.
                        </p>
                        <Input
                            type='text'
                            onChange={(e) => this.setState({ search: e.target.value })}
                            name='newPassword'
                            id='newPassword'
                            onKeyPress={this.handleKeyPress}
                            placeholder='New Password'

                        />
                        <br />
                        <Input
                            type='text'
                            onChange={(e) => this.setState({ search: e.target.value })}
                            name='confirmPassword'
                            id='confirmPassword'
                            onKeyPress={this.handleKeyPress}
                            placeholder='Confirm Password'

                        />
                        <br />
                        <Button
                            color='primary'
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Container>


        );
    }
}
